import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import randomHash from 'random-hash'
import {
  FormControl,
  TextField,
  Popper,
  Button,
  Collapse,
  Paper,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  Tooltip,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { Loader } from 'core/components/Loader'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import { prop } from 'core/_helpers/prop'
import { getResourceLangs } from 'core/_helpers/getResourceLangs'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
    marginTop: 10,
  },
  formControllFullWidth: {
    minWidth: '100%',
  },
  autocomplete: {
    minWidth: 260,
  },
  compare: {
    marginTop: 5,
  },
}))

export const ResourceIksorisType = ({
  name,
  value,
  label1,
  label2 = null,
  label3 = null,
  label4 = null,
  label5 = null,
  endpoint1 = null,
  endpoint2,
  endpoint3,
  endpoint4,
  endpoint5,
  resources = null,
  error = false,
  renderError = false,
  disabled = false,
  fullWidth = false,
  validators,
  setValue,
  getValue,
  setError,
  titleAccessor1,
  titleAccessor2,
  titleAccessor3,
  titleAccessor4,
  titleAccessor5,
  name1,
  name2 = null,
  name3 = null,
  name4 = null,
  name5 = null,
}) => {
  const [id1] = useState(randomHash())
  const [id2] = useState(randomHash())
  const [id3] = useState(randomHash())
  const [id4] = useState(randomHash())
  const [id5] = useState(randomHash())

  const [options, setOptions] = useState(getValue(name1))
  const [choices, setChoices] = useState(resources || [])

  const [value2, setValue2] = useState(getValue(name2))
  const [options2, setOptions2] = useState([])
  const [choices2, setChoices2] = useState([])

  const [options3, setOptions3] = useState([])
  const [choices3, setChoices3] = useState([])

  const [value4, setValue4] = useState(getValue(name4))
  const [options4, setOptions4] = useState([])
  const [choices4, setChoices4] = useState([])

  const [options5, setOptions5] = useState([])
  const [choices5, setChoices5] = useState([])

  const [isFetching1, setFetching1] = useState(true)
  const [isFetching2, setFetching2] = useState(true)
  const [isFetching3, setFetching3] = useState(true)
  const [isFetching4, setFetching4] = useState(true)
  const [isFetching5, setFetching5] = useState(true)

  const [urlSolo, setUrlSolo] = React.useState('')
  const [langSolo, setLangSolo] = React.useState('')
  const [urlGroup, setUrlGroup] = React.useState('')
  const [langGroup, setLangGroup] = React.useState('')

  const handleChange = (e, option) => {
    setValue(name1, option ? option.value : 0)
    setUrlSolo('')
    setLangSolo('')
  }
  const handleChange2 = (e, option) => {
    setValue(name2, option ? option.value : 0)
    setValue2(option ? option.value : 0)

    setFetching3(true)
    setValue(name3, 0)
    setOptions3([])
    getAjaxValuesWhenChange(option ? option.value : null, 'topic')
    setUrlSolo('')
    setLangSolo('')
  }

  const handleChange3 = (e, option) => {
    setValue(name3, option ? option.value : 0)
    setUrlSolo('')
    setLangSolo('')
  }

  const handleChange4 = (e, option) => {
    setValue(name4, option ? option.value : 0)
    setValue4(option ? option.value : 0)

    setFetching5(true)
    setValue(name5, 0)
    setOptions5([])
    getAjaxValuesWhenChangeGroup(option ? option.value : null, 'topic')
    setUrlGroup('')
    setLangGroup('')
  }

  const handleChange5 = (e, option) => {
    setValue(name5, option ? option.value : 0)
    setUrlGroup('')
    setLangGroup('')
  }

  const [show, setShow] = React.useState(false)

  const handleClick = () => {
    setShow(!show)
  }

  function Tcompare(a, b) {
    if (a.translations?.pl.title && b.translations?.pl.title) {
      return new Intl.Collator().compare(
        a.translations.pl.title,
        b.translations.pl.title
      )
    } else if (a.title && b.title) {
      return new Intl.Collator().compare(a.title, b.title)
    }
    return 0
    // if (a.translations.pl.title < b.translations.pl.title) { return -1 } if (a.translations.pl.title > b.translations.pl.title) { return 1  } return 0
  }

  useEffect(
    () => {
      if (!endpoint1 || resources) {
        return
      }

      const controller = new AbortController()
      const { signal } = controller

      if (show && choices.length === 0) {
        setFetching1(true)
        setFetching2(true)
        setFetching3(true)

        fetchDataHandleAuthError(
          endpoint1,
          'GET',
          { signal },
          response => {
            let xsc = response['hydra:member']
            if (xsc.length) {
              xsc = xsc.sort(Tcompare)
            }
            setChoices(xsc)
            setFetching1(false)
          },
          error => {
            if (error.response.title === 'AbortError') {
              return
            }

            notification('error', error.response.detail, error.response.title)
          }
        )
        fetchDataHandleAuthError(
          endpoint2,
          'GET',
          { signal },
          response => {
            let xsc = response['hydra:member']
            if (xsc.length) {
              xsc = xsc.sort(Tcompare)
            }
            setChoices2(xsc)
            setFetching2(false)
            getAjaxValuesWhenChange(value2, 'topic')
          },
          error => {
            if (error.response.title === 'AbortError') {
              return
            }

            notification('error', error.response.detail, error.response.title)
          }
        )

        fetchDataHandleAuthError(
          endpoint4,
          'GET',
          { signal },
          response => {
            let xsc = response['hydra:member']
            if (xsc.length) {
              xsc = xsc.sort(Tcompare)
            }
            setChoices4(xsc)
            setFetching4(false)
            getAjaxValuesWhenChangeGroup(value4, 'topic')
          },
          error => {
            if (error.response.title === 'AbortError') {
              return
            }

            notification('error', error.response.detail, error.response.title)
          }
        )
      }
      return () => controller.abort()
    },
    // eslint-disable-next-line
    [resources, show]
  )

  useEffect(() => {
    const opt = choices?.map(resource => ({
      value: resource['@id'],
      title: prop(resource, titleAccessor1),
    }))
    setOptions(opt)
  }, [choices, titleAccessor1])

  useEffect(() => {
    const opt2 = choices2?.map(resource => ({
      value: resource['@id'],
      title: prop(resource, titleAccessor2),
    }))
    setOptions2(opt2)
  }, [choices2, titleAccessor2])

  useEffect(() => {
    const opt3 = choices3?.map(resource => ({
      value: resource['@id'],
      title: prop(resource, titleAccessor3),
    }))
    setOptions3(opt3)
  }, [choices3, titleAccessor3])

  useEffect(() => {
    const opt4 = choices4?.map(resource => ({
      value: resource['@id'],
      title: prop(resource, titleAccessor4),
    }))
    setOptions4(opt4)
  }, [choices4, titleAccessor4])

  useEffect(() => {
    const opt5 = choices5?.map(resource => ({
      value: resource['@id'],
      title: prop(resource, titleAccessor5),
    }))
    setOptions5(opt5)
  }, [choices5, titleAccessor5])

  const classes = useStyles()

  const value1Change = value1 =>
    getValue(value1)
      ? {
          value: getValue(value1),
          title:
            options.find(option => option.value === getValue(value1))?.title ||
            '',
        }
      : null

  const value2Change = value2 =>
    getValue(value2)
      ? {
          value: getValue(value2),
          title:
            options2.find(option => option.value === getValue(value2))?.title ||
            '',
        }
      : null

  const value3Change = value3 =>
    getValue(value3)
      ? {
          value: getValue(value3),
          title:
            options3.find(option => option.value === getValue(value3))?.title ||
            '',
        }
      : null

  const value4Change = value4 =>
    getValue(value4)
      ? {
          value: getValue(value4),
          title:
            options4.find(option => option.value === getValue(value4))?.title ||
            '',
        }
      : null

  const value5Change = value5 =>
    getValue(value5)
      ? {
          value: getValue(value5),
          title:
            options5.find(option => option.value === getValue(value5))?.title ||
            '',
        }
      : null

  const getAjaxValuesWhenChange = (valueSel, parentSel) => {
    if (!valueSel) {
      setFetching3(false)
      return
    }

    const controller = new AbortController()
    const { signal } = controller

    const urles = `${endpoint3.replace('/0/0', '')}/${valueSel}/${parentSel}`

    fetchDataHandleAuthError(
      urles,
      'GET',
      { signal },
      response => {
        if (response['hydra:member']) {
          setChoices3(response['hydra:member'])
        }
        setFetching3(false)
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }
        notification('error', error.response.detail, error.response.title)
      }
    )
    return () => controller.abort()
  }
  const getAjaxValuesWhenChangeGroup = (valueSel, parentSel) => {
    if (!valueSel) {
      setFetching5(false)
      return
    }

    const controller = new AbortController()
    const { signal } = controller

    const urles = `${endpoint5.replace('/0/0', '')}/${valueSel}/${parentSel}`

    fetchDataHandleAuthError(
      urles,
      'GET',
      { signal },
      response => {
        if (response['hydra:member']) {
          setChoices5(response['hydra:member'])
        }
        setFetching5(false)
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }
        notification('error', error.response.detail, error.response.title)
      }
    )
    return () => controller.abort()
  }

  const handleChangeSelectLanguageSolo = event => {
    // /api/events/ticket/url/{lang}/type/{type}/for/{event}/{topic}/{term}

    const type = 'solo'
    const lang = event.target.value
    setLangSolo(lang)
    const urles = `/api/events/ticket/url/${lang}/type/${type}/for/${getValue(
      name1
    )}/${getValue(name2)}/${getValue(name3)}`

    fetchDataHandleAuthError(
      urles,
      'GET',
      {},
      response => {
        if (response['url']) {
          setUrlSolo(response['url'])
        }
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const handleChangeSelectLanguageGroup = event => {
    // /api/events/ticket/url/{lang}/type/{type}/for/{event}/{topic}/{term}

    const type = 'group'
    const lang = event.target.value
    setLangGroup(lang)
    const urles = `/api/events/ticket/url/${lang}/type/${type}/for/0/${getValue(
      name4
    )}/${getValue(name5)}`

    fetchDataHandleAuthError(
      urles,
      'GET',
      {},
      response => {
        if (response['url']) {
          setUrlGroup(response['url'])
        }
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        color="primary"
        size="small"
      >
        {show ? 'Ukryj wybór biletu' : 'Pokaż wybór biletu'}
      </Button>
      <br />
      {/* {show ? ( */}
      <Collapse in={show}>
        <Paper
          elevation={4}
          style={{ backgroundColor: '#e9ecf0', padding: '10px' }}
        >
          <div>
            INDYWIDUALNY
            <br />
            Wybierz wydarzenie lub temat i termin aby bilet indywidualny był
            dostępny.
          </div>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              {isFetching1 ? (
                <Loader style={{ paddingTop: '10px' }} />
              ) : (
                <FormControl
                  className={clsx(
                    classes.formControl,
                    fullWidth && classes.formControllFullWidth
                  )}
                  error={renderError && !!error}
                >
                  <Autocomplete
                    id={id1}
                    name={name1}
                    options={options}
                    // multiple={false}
                    getOptionLabel={option => option.title}
                    getOptionSelected={(option, value) => {
                      return option.value === value.value
                    }}
                    onChange={handleChange}
                    value={value1Change(name1)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={
                          translate(label1.text || label1) +
                          (validators && validators.includes('required')
                            ? ' *'
                            : '')
                        }
                        variant="outlined"
                      />
                    )}
                    PopperComponent={props => (
                      <Popper
                        {...props}
                        style={{ width: 'auto' }}
                        placement="bottom-start"
                      />
                    )}
                    disabled={disabled || !choices.length}
                    classes={{ root: classes.autocomplete }}
                  />
                </FormControl>
              )}
            </Grid>

            <Grid item xs={4}>
              {isFetching2 ? (
                <Loader style={{ paddingTop: '10px' }} />
              ) : (
                <FormControl
                  className={clsx(
                    classes.formControl,
                    fullWidth && classes.formControllFullWidth
                  )}
                  error={renderError && !!error}
                  disabled={disabled}
                >
                  <Autocomplete
                    id={id2}
                    name={name2}
                    options={options2}
                    // multiple={false}
                    getOptionLabel={option => {
                      return option.title
                    }}
                    getOptionSelected={(option, value) => {
                      return option.value === value.value
                    }}
                    onChange={handleChange2}
                    value={value2Change(name2)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={
                          translate(label2.text || label2) +
                          (validators && validators.includes('required')
                            ? ' *'
                            : '')
                        }
                        variant="outlined"
                      />
                    )}
                    PopperComponent={props => (
                      <Popper
                        {...props}
                        style={{ width: 'auto' }}
                        placement="bottom-start"
                      />
                    )}
                    disabled={disabled || !choices2.length}
                    classes={{ root: classes.autocomplete }}
                  />
                </FormControl>
              )}
            </Grid>

            <Grid item xs={4}>
              {isFetching3 ? (
                <Loader style={{ paddingTop: '10px' }} />
              ) : (
                <FormControl
                  className={clsx(
                    classes.formControl,
                    fullWidth && classes.formControllFullWidth
                  )}
                  error={renderError && !!error}
                  disabled={disabled || (!choices.length && !choices2.length)}
                >
                  <Autocomplete
                    id={id3}
                    name={name3}
                    // multiple={false}
                    options={options3}
                    getOptionLabel={option => {
                      return option.title
                    }}
                    getOptionSelected={(option, value) => {
                      return option.value === value.value
                    }}
                    onChange={handleChange3}
                    value={value3Change(name3)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={
                          translate(label3.text || label3) +
                          (validators && validators.includes('required')
                            ? ' *'
                            : '')
                        }
                        variant="outlined"
                      />
                    )}
                    PopperComponent={props => (
                      <Popper
                        {...props}
                        style={{ width: 'auto' }}
                        placement="bottom-start"
                      />
                    )}
                    disabled={disabled}
                    classes={{ root: classes.autocomplete }}
                  />
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <InputLabel id="lang-solo-select-label">
                  link do biletu
                </InputLabel>
                <Select
                  labelId="lang-solo-select"
                  id="lang-solo-select-helper"
                  value={langSolo}
                  onChange={handleChangeSelectLanguageSolo}
                  style={{ width: 100 }}
                >
                  {getResourceLangs().map(lang => {
                    return <MenuItem value={lang}>{lang}</MenuItem>
                  })}
                </Select>
                <FormHelperText>
                  wybierz język by wygenerować link
                </FormHelperText>
                <FormHelperText style={{ paddingTop: 5, fontWeight: 800 }}>
                  <span
                    onClick={() => {
                      navigator.clipboard.writeText(urlSolo)
                    }}
                  >
                    {urlSolo}
                  </span>
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <div style={{ marginTop: 20 }}>
            GRUPOWY
            <br />
            Wybierz temat, opcjonalnie termin aby bilet grupowy był dostępny.
          </div>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              {isFetching4 ? (
                <Loader style={{ paddingTop: '10px' }} />
              ) : (
                <FormControl
                  className={clsx(
                    classes.formControl,
                    fullWidth && classes.formControllFullWidth
                  )}
                  error={renderError && !!error}
                  disabled={disabled}
                >
                  <Autocomplete
                    id={id4}
                    name={name4}
                    options={options4}
                    // multiple={false}
                    getOptionLabel={option => {
                      return option.title
                    }}
                    getOptionSelected={(option, value) => {
                      return option.value === value.value
                    }}
                    onChange={handleChange4}
                    value={value4Change(name4)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={
                          translate(label4.text || label4) +
                          (validators && validators.includes('required')
                            ? ' *'
                            : '')
                        }
                        variant="outlined"
                      />
                    )}
                    PopperComponent={props => (
                      <Popper
                        {...props}
                        style={{ width: 'auto' }}
                        placement="bottom-start"
                      />
                    )}
                    disabled={disabled || !choices4.length}
                    classes={{ root: classes.autocomplete }}
                  />
                </FormControl>
              )}
            </Grid>

            <Grid item xs={4}>
              {isFetching5 ? (
                <Loader style={{ paddingTop: '10px' }} />
              ) : (
                <FormControl
                  className={clsx(
                    classes.formControl,
                    fullWidth && classes.formControllFullWidth
                  )}
                  error={renderError && !!error}
                  disabled={disabled || !choices4.length}
                >
                  <Autocomplete
                    id={id5}
                    name={name5}
                    // multiple={false}
                    options={options5}
                    getOptionLabel={option => {
                      return option.title
                    }}
                    getOptionSelected={(option, value) => {
                      return option.value === value.value
                    }}
                    onChange={handleChange5}
                    value={value5Change(name5)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={
                          translate(label5.text || label5) +
                          (validators && validators.includes('required')
                            ? ' *'
                            : '')
                        }
                        variant="outlined"
                      />
                    )}
                    PopperComponent={props => (
                      <Popper
                        {...props}
                        style={{ width: 'auto' }}
                        placement="bottom-start"
                      />
                    )}
                    disabled={disabled}
                    classes={{ root: classes.autocomplete }}
                  />
                </FormControl>
              )}
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <InputLabel id="lang-solo-select-label">
                  link do biletu
                </InputLabel>
                <Select
                  labelId="lang-solo-select"
                  id="lang-solo-select-helper"
                  value={langGroup}
                  onChange={handleChangeSelectLanguageGroup}
                  style={{ width: 100 }}
                >
                  {getResourceLangs().map(lang => {
                    return <MenuItem value={lang}>{lang}</MenuItem>
                  })}
                </Select>
                <FormHelperText>
                  wybierz język by wygenerować link
                </FormHelperText>
                <Tooltip title="kliknij aby skopiować link" aria-label="add">
                  <FormHelperText style={{ paddingTop: 5, fontWeight: 800 }}>
                    <span
                      onClick={() => {
                        navigator.clipboard.writeText(urlGroup)
                      }}
                    >
                      {urlGroup}
                    </span>
                  </FormHelperText>
                </Tooltip>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Collapse>
      {/* // ) : null} */}
    </>
  )
}
ResourceIksorisType.propTypes = {}
