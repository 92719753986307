export const modifyPropertiesByPage = (properties, resource, schema) => {
  let modifiedProperties = Object.assign({}, properties)
  modifiedProperties.translations.properties = Object.assign(
    {},
    properties.translations.properties
  )

  let idname = resource.idName
    ? resource.idName
    : resource.parentIdname !== 'page_exhibition'
    ? resource.parentIdname
    : null

  switch (idname) {
    case 'page_contact':
      modifiedProperties.translations.properties.text = Object.assign(
        {},
        modifiedProperties.translations.properties.text,
        {
          description: 'Adres i najważniejsze dane kontaktowe',
          type: 'tinymce',
        }
      )
      modifiedProperties.translations.properties.shortLead = Object.assign(
        {},
        modifiedProperties.translations.properties.shortLead,
        {
          description: 'Opis mapy',
        }
      )
      delete modifiedProperties.archive
      delete modifiedProperties.tags
      delete modifiedProperties.pageDate
      delete modifiedProperties.photo
      return modifiedProperties
    case 'page_search':
    case 'page_sitemap':
    case 'page_shop':
      delete modifiedProperties.translations.properties.text
      delete modifiedProperties.translations.properties.subtitle
      delete modifiedProperties.translations.properties.shortLead
      delete modifiedProperties.tags
      delete modifiedProperties.pageDate
      delete modifiedProperties.archive
      return modifiedProperties
    case 'page_education':
      delete modifiedProperties.translations.properties.shortLead
      delete modifiedProperties.tags
      delete modifiedProperties.pageDate
      delete modifiedProperties.archive
      return modifiedProperties
    case 'page_exhibition':
      modifiedProperties.audiodescription = {
        description: 'Audiodeskrypcja',
        hint: 'link',
        maxLength: 511,
        type: 'string',
      }
      delete modifiedProperties.translations.properties.subtitle
      delete modifiedProperties.translations.properties.shortLead
      delete modifiedProperties.translations.properties.photoAlt
      delete modifiedProperties.translations.properties.photoDesc
      delete modifiedProperties.photo
      delete modifiedProperties.tags
      delete modifiedProperties.pageDate
      delete modifiedProperties.archive
      return modifiedProperties
    case 'page_competition':
      delete modifiedProperties.translations.properties.shortLead
      delete modifiedProperties.tags
      delete modifiedProperties.pageDate
      return modifiedProperties
    case 'page_collection':
    case 'page_knowledge':
    case 'page_sobiesciana':
    case 'page_nature':
    case 'page_study':
    case 'page_gis':
      modifiedProperties.translations.properties.shortLead = Object.assign(
        {},
        properties.translations.properties.shortLead
      )
      modifiedProperties.translations.properties.shortLead.description =
        'Opis do kafelka "wiedza online"'
      delete modifiedProperties.translations.properties.subtitle
      delete modifiedProperties.archive
      return modifiedProperties

    case 'page_social_archive':
      modifiedProperties.translations.properties.shortLead = Object.assign(
        {},
        properties.translations.properties.shortLead
      )
      modifiedProperties.translations.properties.shortLead.description =
        'Opis do kafelka "wiedza online"'
      delete modifiedProperties.translations.properties.subtitle
      delete modifiedProperties.archive
      return modifiedProperties

    case 'page_visit_group':
    case 'page_visit_solo':
    case 'page_visit_family':
      delete modifiedProperties.translations.properties.shortLead
      delete modifiedProperties.archive
      return modifiedProperties

    case 'page_royal_residence':
      delete modifiedProperties.translations.properties.shortLead
      delete modifiedProperties.archive
      return modifiedProperties

    case 'page_openhour':
      delete modifiedProperties.translations.properties.shortLead
      delete modifiedProperties.archive
      return modifiedProperties

    default:
      switch (resource.parentIdname) {
        case 'page_cooperation':
          delete modifiedProperties.translations.properties.subtitle
          delete modifiedProperties.archive
          return modifiedProperties
        default:
          break
      }

      delete modifiedProperties.translations.properties.subtitle
      delete modifiedProperties.translations.properties.shortLead
      delete modifiedProperties.archive
      return modifiedProperties
  }
}
